<template>
  <div>
    <div class="card height">
      <h4 class="text-black text-center my-2">Category</h4>
    </div>
    <div class="card category">
      <div class="mt-3 mx-3">
        <h5 v-b-toggle:my-collapse class="d-flex justify-content-between">
          Order Medicine
          <span class="pointer when-open">
            <b-icon-chevron-down></b-icon-chevron-down>
          </span>
          <span class="pointer when-closed">
            <b-icon-chevron-right></b-icon-chevron-right>
          </span>
        </h5>
      </div>
      <b-collapse id="my-collapse" visible appear>
        <div class="ml-4">
          <button class="pointer color">Search specific item</button>
          <button class="pointer" @click="$router.push(`/orderPrescription`)"
            >Order with prescription</button>
          <button class="pointer" @click="$router.push({
              name: 'pharmacy-Shop',
              params: {
                id: marketList[6]._id,
              },
            })">Go to Pharmacy Shop</button>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isCategory: false,
    };
  },
  computed: {
    ...mapState({
      marketList: (state) => state["home"].marketList,
    }),
  },

  async mounted() {},
};
</script>

<style>
.height {
  height: 50px !important;
  background-color: #f9f9f9 !important;
}

.category {
  width: 100%;
  height: 350px;
}
.color {
  color: #fe7b08;
}
.pointer:hover {
  color: #fe7b08;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
